import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Workbox } from 'workbox-window'

import App from './App.vue'
import router from './router'

import './assets/css/main.scss'
import './plugins/swiper.js'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)

// app.mount('#app')

const workbox = new Workbox('/service-worker.js')

workbox.addEventListener('waiting', (event) => {
  if (confirm('A new version is available. Do you want to update? - test')) {
    workbox.addEventListener('controlling', (event) => {
      window.location.reload()
    })

    workbox.messageSW({ type: 'SKIP_WAITING' })
  }
})

workbox.register().then(() => {
  // Service worker registration is successful
  console.log('Service worker registration is successful - test')
  app.mount('#app')
}).catch((error) => {
  console.error('Error during service worker registration:', error)
})